import * as React from "react";
import { Alert, Box, VStack, StackDivider, Heading, SimpleGrid } from "@chakra-ui/react";
import { AlbumCard, MediaType, PerformanceType } from "../../components/AlbumCard";
import axios, { AxiosResponse } from "axios";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { Url } from "url";

type Release = {key: string, title: string, description: string, year: number, duration: string, songCount: number, medium: string, performanceType: string, imageURL: string, playURL: Url };
type Ensemble = {key:string, name:string, description: string, releases: Release[]};

export const MusicPage = () => {
  const {data, error, status, isFetching} = FetchEnsembles();

  return (
    <>
      <Heading as="h1">Music</Heading>
      <VStack align="normal" divider={<StackDivider borderColor="gray.200" borderStyle="dashed" />}>
        <Box my={4}>
          Aside from loving music and the creative process, playing music has pretty much always been about friends for me. Meeting new people from all over, and creating close bonds through shared experiences that last decades.
          I realized that this part of experience was important to me, and I made it my main goal when creating music. Not popularity, money, or any particular predetermined sound.
          That framing makes every one of these projects a huge success. I look forward to many more.
        </Box>
        {isFetching || status === "loading" ? (
            "Loading Ensembles..."
          ) : status === "error" && error instanceof Error ? (
            <Alert my="4" mx="auto">Error: {error.message}</Alert>
          ) : (
            <>
              {data?.map((ensemble:Ensemble, ensemble_index:number) => (
                <Box key={ensemble.key}>
                  <Heading as="h2" size="md">{ensemble.name}</Heading>
                  <Alert my="4" mx="auto">{ensemble.description}</Alert>
                  <SimpleGrid columns={{ base: 1, md: 2, lg:3, xl: 4 }}>
                    {ensemble.releases.map((release) => (
                      <AlbumCard key={release.key} title={release.title} description={release.description} year={release.year} duration={release.duration} songCount={release.songCount} medium={release.medium as MediaType} performanceType={release.performanceType as PerformanceType} imageURL={release.imageURL} playURL={release.playURL} />
                    ))}
                  </SimpleGrid>
                </Box>
              ))}
            </>
          )
        }
      </VStack>
    </>
    );
};


function FetchEnsembles():UseQueryResult<Ensemble[], Error> {
  return useQuery<Ensemble[], Error>(["ensembles"], async () => {
    const response: AxiosResponse<Ensemble[]> = await axios.get<Ensemble[]>(
      "./api/ensembles.json"
    );
    return response.data as Ensemble[];
  });
}
