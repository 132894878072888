import * as React from "react"
import "@fontsource/raleway/400.css"
import "@fontsource/open-sans/700.css"
import {
  ChakraProvider,
  Box,
  extendTheme,
  CSSReset
} from "@chakra-ui/react"
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { HomePage, MusicPage, ReadmePage } from "./routes";
import SiteHeader from "./components/SiteHeader";
import { createBrowserHistory } from 'history'
import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";

const breakpoints = {
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
};

const theme = extendTheme({
  styles: {
    global: {
      '.link': {
        color: "blue.500",
        _hover: {
          textDecoration: "underline"
        }
      }
     }
  },
  fonts: {
    heading: "Open Sans",
    body: "Raleway",
  },
  components: {
    List: {
      baseStyle: {
        marginTop: "10px"
      },
    },
    OrderedList: {
      baseStyle: {
        marginTop: ".5em"
      },
    },
    Link: {
      baseStyle: {
        color: "blue.500",
        _hover: {
          textDecoration: "underline"
        },
        _focus: {
          boxShadow: 'none'
        }
      },
    },
    Text: {
      baseStyle: {
        marginTop: ".5em"
      },
    },
    Heading: {
      baseStyle: {
        marginTop: "1em",
        marginBottom: ".5em"
      },
    },
  },
  breakpoints: breakpoints
});

const handleRefreshHash = () => {
  const browserHistory = createBrowserHistory();
  const hash = browserHistory.location.hash;
  if (hash) {
    const path = hash.replace(/^#/, '')
    if (path) {
      browserHistory.replace(path);
    }
  }
}
handleRefreshHash();

const client = new QueryClient();

export const App = () => (
  <QueryClientProvider client={client}>
    <ChakraProvider theme={theme}>
      <CSSReset />
      <Router>
        <SiteHeader />
        <Box textAlign="left" p="5">
          <Routes>
            <Route path="/readme" element={<ReadmePage />} />
            <Route path="/music" element={<MusicPage />} />
            <Route path="/" element={<HomePage />} / >
          </Routes>
        </Box>
      </Router>
    </ChakraProvider>
  </QueryClientProvider>
)
