import * as React from "react"
import {
  Avatar,
  forwardRef,
  AvatarProps
} from "@chakra-ui/react"

export const Logo = forwardRef<AvatarProps, "img">((props, ref) => {
  return <Avatar size="2xl" border="3px" name="Scott" src="./logo.png" {...props} />
})
