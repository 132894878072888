import React from "react";
import { Box, Heading, Flex, Text, Link } from "@chakra-ui/react";
import { Link as RouteLink, useLocation } from "react-router-dom";
import { ColorModeSwitcher } from "./ColorModeSwitcher"



type MenuLinkProps = {
  path: string
  text: string
}

function MenuItem({path, text}: MenuLinkProps) {
  const router = useLocation()
  const isActive = router.pathname === path

    return (
      <Box borderBottom={isActive ? "4px" : ""} mr={6}>
        <Link as={RouteLink} color="white" to={path} mt={{ base: 4, md: 2 }} display="block" bg="transparent" border="0px" _hover={{textDecor:"none"}}>
          <Text>{text}</Text>
        </Link>
      </Box>
    );
}

const SiteHeader = ({props}: any) => {
  const [show, setShow] = React.useState(false);
  const handleToggle = () => setShow(!show);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1.5rem"
      bg="blue.500"
      color="white"
      {...props}
    >
      <Flex align="center" mr={6}>
        <Heading as="h1" size="lg" letterSpacing={"-.1rem"} mt={1} mb={0}>
          <RouteLink to="/">Scott Mercer</RouteLink>
        </Heading>
      </Flex>

      <Box display={{ base: "block", md: "none" }} onClick={handleToggle}>
        <svg
          fill="white"
          width="12px"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </Box>

      <Box
        display={{ sm: show ? "block" : "none", md: "flex" }}
        width={{ sm: "full", md: "auto" }}
        alignItems="center"
        flexGrow={1}
        onClick={handleToggle}
      >
        <MenuItem path="/readme" text="README"></MenuItem>
        <MenuItem path="/music" text="Music"></MenuItem>
      </Box>

      <Box
        display={{ sm: show ? "block" : "none", md: "block" }}
        mt={{ base: 4, md: 0 }}
      >
        <ColorModeSwitcher />
      </Box>
    </Flex>
  );
  }

export default SiteHeader;
