import * as React from "react";
import {
  Text,
  VStack,
  Code,
  Link
} from "@chakra-ui/react";

import { Logo } from "../../components/Logo";

export const HomePage = () => {
  return <VStack spacing={8}>
    <Logo mt={8} pointerEvents="none" />
    <Text>
      Hi, I'm <Code fontSize="xl">Scott Mercer</Code>.
    </Text>
    <Text align="center">I created this site as an excuse to play with <Link href="https://www.typescriptlang.org/" isExternal>TypeScript</Link>, <Link href="https://reactjs.org/" isExternal>React</Link>, and <Link href="http://chakra-ui.com/" isExternal>Chakra UI!</Link></Text>
    <Text align="center">During the day I work on <Link href="https://torch.io" isExternal>Torch</Link> with the best team ever. We're on a <Link href="https://techcrunch.com/2019/04/03/torch-executive-coaching/" isExternal>mission</Link> to help people be better leaders. Find me on <Link href="https://www.linkedin.com/in/scottrmercer" isExternal>LinkedIn</Link> & <Link href="https://github.com/scottrmercer" isExternal>GitHub</Link>.</Text>
  </VStack>;
};
