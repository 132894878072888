import * as React from "react";
import { Box, Badge, Image, Button, Link } from "@chakra-ui/react";
import { MdHeadset, MdOndemandVideo } from "react-icons/md"
import { Url } from "url";

export enum MediaType {
    Video = "Video",
    Audio = "Audio"
  }

export enum PerformanceType {
    Live = "Live",
    Studio = "Studio",
    Home = "Home"
  }

type AlbumCardProps = {
    title: string,
    description: string,
    year: number,
    duration: string,
    songCount: number,
    medium: MediaType,
    imageURL: string,
    playURL?: Url,
    performanceType: PerformanceType
}

export const AlbumCard = ({title, description, year, duration, songCount, medium, imageURL, playURL, performanceType, ...props}: AlbumCardProps) => {
    var playIcon = <MdHeadset />;
    var playButton = <>Coming Soon</>;
    var linkText = "Hear It";
    var songLabel = (songCount > 1) ? "songs" : "song"
    if (medium === MediaType.Video) {
        playIcon = <MdOndemandVideo />;
        linkText = "Watch It";
    }
    if (playURL){
        // playButton = <><Icon as={playIcon} h={6} w={6} /> <Link target="_blank" href={playURL.toString()}>{linkText}</Link></>;
        playButton = <Link target="_blank" href={playURL.toString()}><Button leftIcon={playIcon} size="sm">{linkText}</Button></Link>;
    }
    return (
            <Box maxW="sm" mb={[2,4,6,8]} mr={[2,4,6,8]} borderWidth="1px" borderRadius="lg" overflow="hidden" {...props}>
              <Image src={imageURL} alt={title} />
              <Box p="6">
                <Box display="flex" alignItems="baseline">
                  <Box>
                    <Badge borderRadius="full" px="2" mr="1" colorScheme="purple">
                        {year}
                    </Badge>
                    <Badge borderRadius="full" px="2" colorScheme="pink">
                        {performanceType}
                    </Badge>
                  </Box>
                  <Box
                    color="gray.500"
                    fontWeight="semibold"
                    letterSpacing="wide"
                    fontSize="xs"
                    textTransform="uppercase"
                    ml="2"
                  >
                    {songCount} {songLabel} &bull; {duration}
                  </Box>
                </Box>

                <Box
                  mt="1"
                  fontWeight="semibold"
                  as="h4"
                  lineHeight="tight"
                  isTruncated
                >
                  {title}
                </Box>

                <Box dangerouslySetInnerHTML={{ __html: description }} />

                <Box display="flex" mt="2" alignItems="center">
                  <Box as="span" color="gray.600" fontSize="sm">
                      {playButton}
                  </Box>
                </Box>
              </Box>
            </Box>
    );
  };
