import { Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription, Heading, Box, VStack, Text, Code, Link, UnorderedList, ListItem, OrderedList, Badge, Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td, Icon, useColorModeValue } from "@chakra-ui/react";
import { MdLocalCafe, MdPhoneIphone, MdMail, MdChat, MdWhatshot } from "react-icons/md"
import * as React from "react";

export const ReadmePage = () => {
  return (
  <VStack align="stretch">
    <Box>
      <Heading as="h1">README</Heading>
      <Code>(version 0.4.3, Updated May 12, 2021)</Code>
      <Text mb={1} mt={4}>This is a basic guide for working with me in a professional context. If you are unfamiliar with the concept of a personal README file, check out Torch co-founder Keegan Walden's article <Link href="https://torch.io/blog/unlike-babies-the-best-managers-come-with-instructions/" target="_blank">Unlike Babies, the Best Managers Come with Instructions</Link>.</Text>
      <Alert my={4} mx="auto">
        <AlertIcon />
        <AlertTitle mr={8}>Note</AlertTitle>
        <AlertDescription>This document does not contain any "company policy", although it may inadvertently overlap and fill in some gaps around it.  Company policies will supersede what you read here.</AlertDescription>
      </Alert>
      <Heading mb={4} as="h2" size="md">Welcome!</Heading>
      <Text>Congratulations on choosing to work with me as your CTO! Below you will find the manual. Most of what you will find in this manual is not original or special, but mostly some good bits I’ve picked up along the way from other leaders whom I’ve found inspiring.</Text>
    </Box>
    <Box>
      <Heading as="h2" size="md">Design Considerations</Heading>
      <Text>Under most circumstances, I view my role as more of a <Code>context wrangler</Code> and <Code>technical vision hombre</Code> than a manager who will command you to do things (although I may have to do that in some circumstances). One really important thing that I can do is act as a <Code>tie breaker</Code>, meaning that I vote last, and you show up with an informed, professional opinion (more on this later).</Text>
      <Text>I will do my best to keep everyone on the team the same page and in a coordinated state. I will do the same outwardly to other teams within Torch. I'm also here to advocate on your behalf to secure resources, build consensus, shield you from distraction and clear blockers. You will see as you read on, that such a loosely coupled system requires mutual trust to operate.</Text>
      <Heading as="h3" size="sm">Enneagram</Heading>
      <Text>I'm a <Link href="https://www.enneagraminstitute.com/type-9" target="_blank">type-9</Link> on the Enneagram.</Text>
      <Box bg={useColorModeValue("gray.100", "gray.700")} color={useColorModeValue("gray.800", "gray.300")} borderLeft="4px" borderLeftColor="blue.500" padding={4} marginLeft={4} my={4}>Nines are accepting, trusting, and stable. They are usually creative, optimistic, and supportive, but can also be too willing to go along with others to keep the peace. They want everything to go smoothly and be without conflict, but they can also tend to be complacent, simplifying problems and minimizing anything upsetting. They typically have problems with inertia and stubbornness. At their Best: indomitable and all-embracing, they are able to bring people together and heal conflicts.</Box>
      <Text>Every Enneagram type has strengths and limitations. I work hard to be aware of these governors with the intent of being the best "9" I can be.
      Through coaching and therapy, I continuously work to gain deeper understanding of myself.  These practices have helped me immensely. It's not about "fixing problems", it's about always improving.  Some of what I once thought were my limits now seem silly.
      There's always a new limit waiting around the next corner, however - and I always have more work to do. If we're not hitting our limits, we're not growing. Keep the heat on<Icon as={MdWhatshot} h={5} w={5} verticalAlign="middle" color="red.500" />.</Text>
      <Heading as="h3" size="sm">Miscellaneous Style Preferences</Heading>
      <UnorderedList ml={8}>
        <ListItem>Fix broken things first</ListItem>
        <ListItem>Constantly deliver small improvements over big-bang overhauls</ListItem>
        <ListItem>Put the customer first, always</ListItem>
        <ListItem>Don’t let “perfect” be the enemy of “good”</ListItem>
        <ListItem>Total transparency around mistakes, without judgement</ListItem>
        <ListItem>Consider options before making decisions</ListItem>
      </UnorderedList>
    </Box>
    <Box>
      <Heading as="h2" size="md">End-User Requirements</Heading>
      <Text>I expect everyone on the team to be leaders - influencing others, empowering to gathering groups, building consensus, independently making informed decisions, and having an impact on the organization that we strive to be.
      Leaders do not blindly follow orders, and they always speak up when something is amiss. If I am doing a good job context setting, you should have a good feel for what to work on next, and the right way things should be done. And whenever that is not the case, never be afraid to ask.</Text>
      <Text>Even if you are an IC (Individual Contributor) - I will still expect you to be a leader. You can do this following ways:</Text>
      <OrderedList ml={8}>
        <ListItem>Being a resource for others, sharing your knowledge and expertise.</ListItem>
        <ListItem>Acting like an owner, and being accountable for your domain and to your 'mates.</ListItem>
        <ListItem>Not asking me what to do. (Unless your totally stumped.) Tell me what <strong>you intend</strong> to do and why. Most of the time you will know better than I do because you're closer to the action. I'll ask questions and maybe have a suggestion to build upon. I might object, but if you're being a leader I'll likely say "very well".</ListItem>
        <ListItem>Asking "why?", and taking the time to understand the things driving what we do.</ListItem>
      </OrderedList>
      <Heading as="h3" size="sm">Motivation</Heading>
      <Text>I hope to have the Torch mission be our main motivator. I believe that having a shared mission and commitment to each other is far more effective than free lunches, ping-pong tables and kegerators (although all those things sound pretty great!). </Text>
      <Text>I like to think of myself as an empathetic person. If I give you candid feedback, I’m not judging you. It’s because I care about your success, and it’s important that we sweat the details together. I’m trying to do this more often.  Likewise, I will apply this same level of candor to how I respond to your questions. There will occasionally be few areas in which I can not be totally transparent with you, and in no case will I lie to you. </Text>
      <Heading as="h3" size="sm">Community Participation</Heading>
      <Text>Community Participation is part of the engineering culture we are trying to build. Speaking, participating in user groups, mentoring, and open source contributions are all ways for us to be good community members. The benefits are numerous, both altruistic and from a business perspective.</Text>
      <Heading as="h3" size="sm">Working Remotely</Heading>
      <Text>In our field, we have the privilege of being able to work in a 100% remote fashion. However, I do find value in face-to-face interactions. These types interactions are important to building trust and establishing deep connections with our peers.  For some, the most creative and collaborative interactions happen in person.</Text>
      <Text>In lieu of traditional in-office day-to-day face time, I advocate for the following: </Text>
      <OrderedList ml={8}>
        <ListItem>If you're near other Torchio's, get together with them. Take a hike, grab tacos or a beer. Organize a lunch (the company will pay for it).</ListItem>
        <ListItem>For virtual meetings:
          <UnorderedList ml={8}>
            <ListItem>Go camera-on when possible. Your facial expressions and body language are an important tool that we use to communicate and empathize with each other.</ListItem>
            <ListItem>Give others and yourself opportunities to share personal information. Make this part of the ritual. What was your top personal win of the last week? What are you excited about for the coming <Code>{'{insert_season}'}</Code>? </ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem>Travel. We'll do our best to arrange in person gatherings, like our once-quarterly tech/product week. We'll also hopefully get back to a full-company retreat in the near future. </ListItem>
        <ListItem>If you're near an office, occasionally work from it. There will be people there from other departments that you may not meet otherwise.</ListItem>
        <ListItem>If you do choose to work from an office, you must still work in a "remote style". Keeping remote-friendly habits universal is necessary for the system to work to ensure that all participants are on the same level ground.
        <UnorderedList ml={8}>
            <ListItem>If you have a meeting, and even just 1 attendee is remote while others are not, it should be run as a fully remote meeting - even the participants at the common location should be individually present the call. No "room cams".</ListItem>
            <ListItem>In person meetings should adhere to the same open agenda & note-taking procedures as remote ones.</ListItem>
          </UnorderedList>
        </ListItem>
      </OrderedList>
      <Text>I personally like to keep home and work fairly well separated, so you will often find me in the Buffalo office (or another one if travelling).</Text>
      <Heading as="h3" size="sm">Extra Hours, Nights, Weekends</Heading>
      <Text>I value your personal time, and will always work to defend it.  With a product roadmap spanning years, there will always be an unlimited amount of "work to be done", but a limited number of healthy work hours available to us personally.
      Fatigue is the antidote for creativity and inspiration. It is up to all of us to manage our commitments and push back when expectations are unreasonable. I will always back you up on this or get you extra help when you need me to.
      I do a bit of work off hours here and there when I’m feeling inspired or want to polish my skills, but for the most part downtime should be used to recharge.  I will expect you to work (or be available) in the case of a production outage or other
      emergent situation that can be considered a existential threat to Torch or a customer engagement. When this does happen, I’ll encourage you to make up for it by taking some time off, depending on the severity of the situation.
      Conversely, I think it's reasonable expect you to use flexible hours to offset the impact of other unplanned absence (such as a taking your car to the shop, hamster to the vet, etc), especially when deliverables are at stake. If you have an off-day where a distraction eats up your productivity, please try to offset it when the time is right. </Text>
      <Heading as="h3" size="sm">Work Quality</Heading>
      <Text>Treat what we do as a craft, and take pride in your contribution. Ask the question that the spec or design comp does not address, and don’t be silent if something is amiss. Question everything, but be malleable enough to accept that there will always be uncertainty. Strive to write reusable, well tested code without repeating yourself. Be aware of, and take style cues from the things that work. Read the documentation, and follow the process. Always leave things better than you find them. </Text>
      <Heading as="h3" size="sm">Time Off</Heading>
      <Text>Torch has an “flexible” vacation policy, which can be a wonderful thing if you use it. (Note: use it!) I insist that each member of the team will take at least 3 weeks off per year. Bare minimum. I personally try to have off about 1 week per quarter. </Text>
      <Text>When you’re on vacation, feel free to totally disconnect. I may call you if it is something urgent (see Private APIs), but will do everything in my power to avoid that if possible.  Additionally, I’m open to “working vacations” (with structured on/off time defined in advance) if it fits our schedule and impact to deliverables is managed. </Text>
      <Text>Personally, I find it hard to unwind if I don’t check slack/email once a day on vacation. However, I only respond to matters of high importance in these cases.  I don’t expect you to follow this example, and prefer that you do whatever works best for you. Feel free to pull that SIM card!</Text>
    </Box>
    <Box>
      <Heading as="h2" size="md">Private API</Heading>
      <Heading as="h3" size="sm">Communicating with Me</Heading>
      <Text>Depending on the urgency of your need, follow these guidelines when you need me. They can be used in combination for maximum effect or irritation.</Text>
      <Table my={4} variant="simple">
        <Thead>
          <Tr>
            <Th>Priority / Method</Th>
            <Th>Notes</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td valign="top"><Badge borderRadius="full" px="2" colorScheme="red">URGENT</Badge>
                <Text><Icon as={MdPhoneIphone} h={5} w={5} verticalAlign="middle" mr={1} />Call or Text</Text></Td>
            <Td>
              During non-business hours, things deemed urgent might include:
              <UnorderedList my={2} ml={8}>
                <ListItem>A security incident</ListItem>
                <ListItem>A client facing system outage</ListItem>
                <ListItem>Emergency disabling of an employee or client account</ListItem>
                <ListItem>Personal freakout</ListItem>
              </UnorderedList>
            </Td>
          </Tr>
          <Tr>
            <Td valign="top"><Badge borderRadius="full" px="2" colorScheme="yellow">TIME SENSITIVE</Badge>
            <Text><Icon as={MdChat} h={5} w={5} verticalAlign="middle" mr={1} />Slack DM</Text></Td>
            <Td>I usually respond fairly quickly to slack messages. During off-hours, I may or may not respond quickly depending on my availability. I generally do check occasionally in these circumstances.</Td>
          </Tr>
          <Tr>
            <Td valign="top"><Badge borderRadius="full" px="2" colorScheme="purple">OTHER</Badge>
            <Text><Icon as={MdMail} h={5} w={5} verticalAlign="middle" mr={1} />Email, Github, or JIRA @mention or assignment</Text></Td>
            <Td>For non-time sensitive items, these contact methods are suitable, however I do not check these as often as the above methods. </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
    <Box>
      <Heading as="h2" size="md">Events</Heading>
      <Heading as="h3" size="sm">Daily Stand-Ups</Heading>
      <Text>It’s expected that we all participate in stand-up daily. Your POD lead may make arrangements ahead of time to accommodate individual scheduling needs or asynchronous updates. If so, it will be expected that you adhere to whatever is prescribed. Sames goes for other critical team rituals such as retro, planning, backlog grooming.</Text>
      <Heading as="h3" size="sm">Weekly 1:1s</Heading>
      <Text>If you report to me directly, we will have a weekly 1:1 for 30 minutes. This is your time, and if you do not have anything to talk about or you’re just “in the zone” on something, let me know and we can cancel or reschedule. While the occasion “skip” is ok, we shouldn’t ever skip 2 in a row under normal circumstances. </Text>
      <Text>It’s up to you what things we discuss, such as:</Text>
      <UnorderedList ml={8}>
        <ListItem>How you are doing, generally speaking</ListItem>
        <ListItem>Things that are (or aren’t) going well</ListItem>
        <ListItem>Areas in which I can advocate for you</ListItem>
        <ListItem>Things that you think I/you can do better</ListItem>
        <ListItem>Ideas you have around process or technology</ListItem>
        <ListItem>Things you want to do (immediate, or long term)</ListItem>
        <ListItem>If there is nothing left, we can always just treat it as a status update or jam sesh.</ListItem>
      </UnorderedList>
    </Box>
    <Box>
      <Heading as="h2" size="md">Incompatibilities</Heading>
      <UnorderedList ml={8}>
        <ListItem>Racism, sexism, or any other form of bigotry</ListItem>
        <ListItem>Dishonesty or non-constructive feedback</ListItem>
        <ListItem>Sloppiness (in all its forms)</ListItem>
        <ListItem>Behavior that is hurtful or disrespectful to colleagues, partners or clients</ListItem>
        <ListItem><Icon as={MdLocalCafe} h={5} w={5} verticalAlign="middle" mr={1} />Decaf.</ListItem>
      </UnorderedList>
    </Box>
    <Box>
      <Heading as="h2" size="md">Defects</Heading>
      <Heading as="h3" size="sm">Known Issues</Heading>
      <UnorderedList ml={8}>
        <ListItem>I’m mostly an introvert at heart, but I try not to let it hold me back. I really prefer working with others - especially in pairs or small groups. I understand the importance of working with larger groups, as exhausting as it might be at times. </ListItem>
        <ListItem>When I get into the zone, I may go off topic as ideas start coming in or I might too quickly jump into problem solving mode before you're finished. If you want me to reign it in, just say so and we can table it.</ListItem>
      </UnorderedList>
      <Heading my={4} as="h3" size="sm">Issue Reporting</Heading>
      <UnorderedList ml={8}>
        <ListItem>If I (or somebody else) do something that is negatively impacting your effectiveness or desire to remain at Torch, please let me know ASAP.</ListItem>
        <ListItem>If you feel I’m leading in a way that is out of line with this document, also let me know! There may be situations in such action this is necessary, and I’ll let you know if that is the case. </ListItem>
        <ListItem>If you’re not comfortable addressing something with me directly, take it to a co-founder.</ListItem>
      </UnorderedList>
    </Box>
  </VStack>
  );
};
